<template>
  <div class="content-box">
    <div class="table-list">
      <el-table
        :data="tableData"
        style="width: 100%"
        max-height="700"
        :header-cell-style="headerCellStyle"
        border
        v-loading="loading"
        :cell-style="cellStyle"
      >
        <el-table-column prop="resultKey" label="诊断结果"> </el-table-column>
        <el-table-column prop="sort" label="权重"> </el-table-column>
        <el-table-column prop="updateTime" label="编辑时间"> </el-table-column>
        <el-table-column prop="resultSuggestion" label="调理建议" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="resultSug" v-html="scope.row.resultSuggestion"></div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="margin-top: 20px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="itemTotal"
      ></el-pagination>

      <el-dialog
        title="调理建议"
        :visible.sync="dialogVisible"
        width="800px"
        :before-close="dialogClose"
      >
        <div class="edit-box">
          <el-form label-width="0" :model="formData" ref="validateForm">
            <el-form-item>
              <quill-editor
                class="quill-editor"
                :value="formData.resultSuggestion"
                @change="inputDesc($event)"
                :options="editorOption"
              ></quill-editor>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose">取消</el-button>
            <el-button type="primary" @click="dialogConfirm">提交</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";

import { suggestionList, update } from "@/api/tongue";

// 工具
import deepCopy from "@/utils/deepCopy";

const initFormData = {
  resultSuggestion: "",
};

@Component
export default class SuggestionList extends Mixins(loading, tablePagination) {
  tableData = [];
  itemTotal = 0;
  dialogVisible = false;
  formData = {
    resultSuggestion: "",
  };
  editorOption = {
    modules: {
      toolbar: [["bold", { color: [] }], [{ size: [] }]],
    },
  };

  mounted() {
    this.getData();
  }

  getData() {
    this.showLoading();
    suggestionList({
      pageNum: this.currentPage,
      pageSize: this.pageSize,
    })
      .then((res) => {
        this.hideLoading();
        this.tableData = res.data.list;
        this.itemTotal = res.data.total;
      })
      .catch((err) => {
        this.hideLoading();
      });
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  handleEdit(row) {
    this.formData = deepCopy(row);
    this.dialogVisible = true;
  }

  // 输入商品详情信息
  inputDesc(val) {
    this.formData.resultSuggestion = val.html;
  }

  dialogClose() {
    this.dialogVisible = false;
    this.$refs["validateForm"].resetFields();
    this.formData = deepCopy(initFormData);
  }
  dialogConfirm() {
    this.$refs["validateForm"].validate((valid) => {
      if (valid) {
        update(this.formData)
          .then((res) => {
            this.dialogClose();
            this.getData();
          })
          .catch((err) => {});
      } else {
        return false;
      }
    });
  }
}
</script>
<style>
.ql-editor {
  height: 500px;
}
.ql-toolbar {
  line-height: normal;
}
.resultSug{
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 25px;
}
</style>

<style lang="scss" scoped src="./style.scss"></style>
